import { Interval } from 'luxon';
import { useMemo } from 'react';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import useGetProgramsByIdHook from '@/store/hooks/useGetProgramsByIdHook/index';
import { getClassroomClassUrl, getClassroomKey } from '@/myphoenix/utils/classroom-functions';
import useGetPersonsHook from '@/store/hooks/useGetPersonsHook/index';
import { sortMemberships } from '@/helpers/filter-courses';
import useGetMembershipsAndCourses from '@/hooks/useGetMembershipsAndCourses';
import { useGetAttendancesByMembershipIdQuery, useGetActivityGroupsBySectionIdQuery } from '@/store/queries/v2/attendances';
import { getCurrentDate, getLastWeekday, diffFromToday, toDateTime } from '@/myphoenix/utils/date-time-functions';
import { FeatureVariableKeys, useFeatureVariableValue } from '@/helpers/feature-provider';

const UseGetAttendanceTileState = () => {
  const personId = getPersonIdFromSessionStorage();
  const today = getCurrentDate();
  const dayOfWeek = today.toFormat('cccc');
  const blackOutUntil = useFeatureVariableValue(FeatureVariableKeys.AttendanceTileBlackoutEndDate, 'N/A', false) as string;
  const featureFlagOn = !blackOutUntil
    || (blackOutUntil !== 'N/A' && diffFromToday(blackOutUntil) <= 0);

  // user attributes:
  //  does the student have the attendance attribute?
  const {
    data: personsHookData,
    isFetching: isPersonsHookFetching,
    isError: isPersonsHookError,
  } = useGetPersonsHook(personId, !featureFlagOn);
  const hasAttribute = useMemo(() => (
    personsHookData?.userDefined?.includes('myphxAttendanceTileGroup')
  ), [personsHookData]);

  // program data:
  //  is a CBE-DA program?
  //  when was last post?
  const {
    data: { primaryProgram = {} },
    isFetching: isProgramsHookFetching,
    isError: isProgramsHookError,
  } = useGetProgramsByIdHook(personId, !featureFlagOn) || {};
  const {
    cbeDaProgram,
    lastActivityDate,
  } = primaryProgram;
  const isCBEDA = cbeDaProgram?.toLowerCase() === 'true';
  const recentPost: 'today' | 'yesterday' | 'thisWeek' | 'none' = useMemo(() => {
    const LAD = toDateTime(lastActivityDate);
    const academicWeekTuesdayStart = getLastWeekday(today, 'Tuesday').startOf('day');
    const academicWeekMondayEnd = academicWeekTuesdayStart.plus({ days: 7 }).minus({ seconds: 1 });
    if (LAD.hasSame(today, 'day')) {
      return 'today';
    }
    if (LAD.hasSame(today.plus({ days: -1 }), 'day') && (today.weekday !== 2)) {
      return 'yesterday';
    }
    if (Interval
      .fromDateTimes(academicWeekTuesdayStart, academicWeekMondayEnd)
      .contains(LAD)) {
      return 'thisWeek';
    }
    return 'none';
  }, [today, lastActivityDate]);

  // course memberships:
  //  what's the current course?
  //  is it the first week?
  //  is the course attendance-tile enabled?
  const {
    memberships,
    courses,
    isFetching: isMembershipsAndCoursesFetching,
    isError: isMembershipsAndCoursesError,
  } = useGetMembershipsAndCourses(personId, !featureFlagOn);
  const currentCourse = useMemo(() => {
    // For purposes of current course attendance, will skip any dropped courses
    const notDroppedMemberships = memberships?.filter((membership) => membership?.statusSubCode !== 'DR' && membership.statusSubCode !== 'TA');
    const sortedMembershipIds = sortMemberships(notDroppedMemberships);
    return sortedMembershipIds.currentMembershipIds.map(
      (membershipId) => courses.find((course: { id: string }) => (course.id === membershipId)),
    )[0];
  }, [memberships, courses]);
  const courseStartDate = toDateTime(currentCourse?.startDate);
  const isFirstWeekOfCourse = courseStartDate <= today
   && today <= courseStartDate.plus({ days: 7 });
  const currentMembership = useMemo(() => (
    memberships.find(
      (membership) => membership.sourceId === currentCourse?.id,
    )
  ), [memberships, currentCourse]);
  const enabledCourses: string[] = (useFeatureVariableValue(FeatureVariableKeys.AttendanceTileEnabledCourses, 'N/A', false) as string).split(',');
  const hasEnabledCourse = useMemo(() => (
    currentCourse?.type !== 'CL'
      && currentCourse?.templateCode
      && (enabledCourses.includes(currentCourse?.templateCode)
        || (enabledCourses.length && enabledCourses[0] === '*'))
  ), [currentCourse, enabledCourses]);

  // course attendance:
  //  how many absences?
  const { data: attendances,
    isFetching: isAttendancesFetching,
    isError: isAttendancesError } = useGetAttendancesByMembershipIdQuery(
    { membershipId: currentMembership?.id },
    { skip: !featureFlagOn || !currentMembership?.id },
  );
  const totalAbsences = useMemo(() => {
    let absences = 0;
    if (attendances) {
      attendances.attendances?.forEach((item: { attendance: string }) => {
        if (item.attendance === 'N') {
          absences += 1;
        }
      });
    }
    return absences;
  }, [attendances]);

  // course activities (weeks):
  //  how many absences are allowed?
  //  are we in a course week (not in a break)?
  const { data: activityGroupsData,
    isFetching: isActivityGroupsFetching,
    isError: isActivityGroupsError } = useGetActivityGroupsBySectionIdQuery(
    { sectionId: currentMembership?.sourceId },
    { skip: !featureFlagOn || !currentMembership?.sourceId },
  );
  const withinACourseWeek = useMemo(() => (
    activityGroupsData?.activitygroups?.some(
      (activityGroup: { startDate: string, endDate: string }) => (
        toDateTime(activityGroup.startDate) <= today && today <= toDateTime(activityGroup.endDate)
      ),
    )
  ), [today, activityGroupsData]);

  const displayDonut = dayOfWeek === 'Friday'
  || dayOfWeek === 'Saturday'
  || dayOfWeek === 'Sunday'
  || (dayOfWeek === 'Monday' && totalAbsences === 0)
  || recentPost !== 'none'
  || (totalAbsences === 1 && dayOfWeek !== 'Monday');
  const hideTooltip = isFirstWeekOfCourse
  && (dayOfWeek === 'Tuesday'
  || dayOfWeek === 'Wednesday'
  || dayOfWeek === 'Thursday')
  && totalAbsences === 0
  && recentPost === 'none';
  const isInAttendance = activityGroupsData?.allowedAbsences
      && totalAbsences <= activityGroupsData?.allowedAbsences;
  const shouldShowAttendanceTile = featureFlagOn && withinACourseWeek && (
    (isInAttendance && hasEnabledCourse) || (isInAttendance && hasAttribute)
  );
  const courseTemplateCode = currentCourse?.templateCode;
  const daysLeftToPost = () => {
    switch (dayOfWeek) {
      case 'Monday':
        return 1;
      case 'Tuesday':
        return 7;
      case 'Wednesday':
        return 6;
      case 'Thursday':
        return 5;
      case 'Friday':
        return 4;
      case 'Saturday':
        return 3;
      case 'Sunday':
        return 2;
      default:
        return 7;
    }
  };
  const classRoomLink = getClassroomClassUrl(
    getClassroomKey(
      { isCBEDA },
    ),
    { sectionId: currentCourse?.id || '' },
  );
  const isInDangerZone = totalAbsences === 1
  && (dayOfWeek === 'Sunday'
  || dayOfWeek === 'Monday')
  && recentPost === 'none';
  const daysLeft = daysLeftToPost();
  return {
    courseTemplateCode,
    totalAbsences,
    recentPost,
    isFirstWeekOfCourse,
    dayOfWeek,
    displayDonut,
    hideTooltip,
    shouldShowAttendanceTile,
    classRoomLink,
    isInDangerZone,
    daysLeft,
    lastActivityDate,
    isFetching: isProgramsHookFetching
    || isPersonsHookFetching
    || isMembershipsAndCoursesFetching
    || isAttendancesFetching
    || isActivityGroupsFetching,
    isError: isProgramsHookError
    || isPersonsHookError
    || isMembershipsAndCoursesError
    || isAttendancesError
    || isActivityGroupsError,
  };
};
export default UseGetAttendanceTileState;
