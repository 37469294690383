import type { GetServerSideProps } from 'next';
import LinkTileSixPack from '@/components/organisms/link-tile-six-pack';
import PhoenixBackground from '@/components/atoms/phoenix-background';
import Greeting from '@/components/molecules/greeting';
import InlineAlertBox from '@/components/molecules/inline-alert';
import { Container, Grid, Box } from '@mui/material';
import ResourceCardGroup from '@/components/molecules/resource-card-group';
import CBEDAEngagement from '@/components/organisms/cbeda-engagement';
import GraduationCountdownCard from '@/components/molecules/graduation-countdown-card';
import ResourceTiles from '@/components/organisms/resource-tile-container';
import AlumniNAEGuidance from '@/components/molecules/alumni-nae-guidance';
import HomeProgramProgress from '@/components/organisms/program-progress/home-program-progress/HomeProgramProgress';
import SearchBoxHome from '@/components/molecules/search-box-home';
import ApplicationsStatus from '@/components/molecules/application-status';
import Engagement from '@/components/molecules/engagement';
import CourseInfo from '@/components/organisms/course-info';
import AttendanceTileContainer from '@/components/molecules/attendance-tile';
import AchievementsModal from '@/components/organisms/achievements-modal';
import { wrapper } from '@/store';
import { getProps } from '@/helpers/ssr';
import { addPerformanceMarkObserver } from '@/helpers/window-functions';
import CareersMFEStepCard from '@/micro-frontends/careers/step-card';
import PreviousCourseContainer from '@/components/molecules/previous-course';
import HomePageInterrupterContainer from '@/components/organisms/home-page-interrupter';

function Home() {
  addPerformanceMarkObserver();
  return (
    <Grid container justifyContent="center">
      <PhoenixBackground>
        <Container maxWidth="md">
          <Box py={3}>
            <Greeting />
          </Box>
          <InlineAlertBox />
          <ApplicationsStatus />
          <CourseInfo />
        </Container>
        <HomePageInterrupterContainer />
        <Container maxWidth="md">
          <Box my={5}>
            <PreviousCourseContainer />
          </Box>
          <Box my={4}>
            <AttendanceTileContainer />
            <CBEDAEngagement />
            <Engagement />
            <GraduationCountdownCard />
            <Box my={2}>
              <HomeProgramProgress />
            </Box>
            <ResourceTiles />
            <CareersMFEStepCard />
          </Box>
          <Box pb={7}>
            <AlumniNAEGuidance />
            <LinkTileSixPack />
          </Box>
        </Container>
      </PhoenixBackground>
      <AchievementsModal showAchievementsModal />
      <Container maxWidth="md" sx={{ paddingTop: 5, paddingBottom: 6.8 }}>
        <ResourceCardGroup />
        <Box mt={5}>
          <SearchBoxHome />
        </Box>
      </Container>
    </Grid>
  );
}

export const getServerSideProps: GetServerSideProps = wrapper.getServerSideProps(
  (store) => async (context) => ({
    ...await getProps(context, store),
  }),
);

Home.pageTitle = 'Home';

export default Home;
